import React from 'react';
import logo from '../assets/logos/dawg_housing_logo.png'; // Import the logo
import { FaUserCircle } from 'react-icons/fa'; // Import profile icon from react-icons

const Header = () => {
  return (
    <header
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Logo and Text Section */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        {/* Logo */}
        <img
          src={logo}
          alt="Dawg Housing Logo"
          style={{
            height: '50px',
            borderRadius: '5px', // Subtle rounding for a modern touch
          }}
        />
        {/* Dawg Housing Text */}
        <div>
          <h1
            style={{
              fontSize: '1.8rem',
              color: '#BA0C2F',
              fontWeight: '800', // Bolder text for prominence
              margin: 0,
              lineHeight: '1.2',
              letterSpacing: '1px',
              fontFamily: "'Roboto', sans-serif", // Modern font family
            }}
          >
            DAWG HOUSING
          </h1>
          <p
            style={{
              fontSize: '0.9rem',
              color: '#666',
              margin: 0,
              marginTop: '2px',
              fontStyle: 'italic',
            }}
          >
            Living Simplified
          </p>
        </div>
      </div>

      {/* Navigation Links */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginLeft: '20px' }}>
        <nav>
          <ul
            style={{
              display: 'flex',
              listStyleType: 'none',
              margin: 0,
              padding: 0,
              gap: '15px',
              alignItems: 'center', // Ensures vertical alignment
            }}
          >
            {['Home', 'Apartments', 'Contact', 'About'].map((item) => (
              <li key={item}>
                <a
                  href={`#${item.toLowerCase()}`}
                  style={{
                    textDecoration: 'none',
                    color: '#BA0C2F',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    border: '2px solid transparent',
                    transition: 'all 0.3s ease-in-out',
                  }}
                  onMouseOver={(e) => {
                    e.target.style.border = '2px solid #BA0C2F';
                    e.target.style.backgroundColor = '#BA0C2F';
                    e.target.style.color = '#ffffff';
                  }}
                  onMouseOut={(e) => {
                    e.target.style.border = '2px solid transparent';
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = '#BA0C2F';
                  }}
                >
                  {item}
                </a>
              </li>
            ))}

            {/* Logout Button */}
            <li>
              <button
                style={{
                  background: '#BA0C2F',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  padding: '10px 15px',
                  borderRadius: '20px',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease-in-out',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  marginLeft: '15px', // Adds space between About and Logout
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#ff5f5f';
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#BA0C2F';
                }}
                onClick={() => alert('Logout functionality to be implemented')}
              >
                Logout
              </button>
            </li>
          </ul>
        </nav>

        {/* My Profile Icon */}
        <button
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => alert('My Profile functionality to be implemented')}
        >
          <FaUserCircle
            size={32} // Profile icon size
            color="#BA0C2F"
            style={{ transition: 'transform 0.2s ease' }}
            onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
            onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
          />
        </button>
      </div>
    </header>
  );
};

export default Header;
