import React, { useState } from 'react';
import Header from './components/Header';
import ApartmentList from './components/ApartmentList';
import MapView from './components/MapView';
import apartmentsData from './data/apartments';

function App() {
  const [selectedApartment, setSelectedApartment] = useState(null);

  const handleSelectApartment = (apartment) => {
    setSelectedApartment(apartment);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Header />
      <div style={{ display: 'flex', flexGrow: 1 }}>
        {/* Apartments List */}
        <div style={{ flex: 1, overflowY: 'auto', padding: '20px' }}>
          <ApartmentList
            apartments={apartmentsData}
            selectedApartment={selectedApartment}
            onSelectApartment={handleSelectApartment}
          />
        </div>

        {/* Map View */}
        <div style={{ flex: 2, position: 'relative' }}>
          <MapView
            apartments={apartmentsData}
            selectedApartment={selectedApartment}
            onSelectApartment={handleSelectApartment}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
