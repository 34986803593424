import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for default marker icons
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Set up default Leaflet icon
const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41], // Position of the "tip" of the icon relative to its location
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapView = ({ apartments, onMarkerClick }) => {
  const [map, setMap] = useState(null);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <MapContainer
        center={[33.9519, -83.3576]} // Athens, GA
        zoom={13}
        style={{ height: '100%', width: '100%' }}
        whenCreated={(mapInstance) => setMap(mapInstance)}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {apartments.map((apartment) => (
          <Marker
            key={apartment.id}
            position={[apartment.lat, apartment.lng]}
            eventHandlers={{
              click: () => {
                onMarkerClick(apartment);
                map.setView([apartment.lat, apartment.lng], 15); // Center on selected marker
              },
            }}
          >
            <Popup>
              <strong>{apartment.name}</strong>
              <p>${apartment.price} / month</p>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapView;
