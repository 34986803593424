const apartmentsData = [
  {
    id: 1,
    name: 'Georgia Heights',
    price: 1400,
    bedrooms: 2,
    bathrooms: 2,
    furnished: true,
    location: 'Athens, GA',
    lat: 33.9519,
    lng: -83.3576,
    image: require('../assets/building/georgiaheights.png'), // Add image path
  },
  {
    id: 2,
    name: 'The Ikon',
    price: 864,
    bedrooms: 3,
    bathrooms: 2,
    furnished: false,
    location: 'Athens, GA',
    lat: 33.9525,
    lng: -83.3605,
    image: require('../assets/building/theikon.png'), // Add image path
  },
  {
    id: 3,
    name: 'The Lodge of Athens',
    price: 750,
    bedrooms: 1,
    bathrooms: 1,
    furnished: true,
    location: 'Athens, GA',
    lat: 33.9505,
    lng: -83.355,
    image: require('../assets/building/thelodgeofathens.png'), // Add image path
  },
  {
    id: 4,
    name: 'The Standard',
    price: 1300,
    bedrooms: 2,
    bathrooms: 2,
    furnished: true,
    location: 'Athens, GA',
    lat: 33.9532,
    lng: -83.3619,
    image: require('../assets/building/thestandard.png'), // Add image path
  },
  {
    id: 5,
    name: 'River Mill Apartments',
    price: 950,
    bedrooms: 1,
    bathrooms: 1,
    furnished: false,
    location: 'Athens, GA',
    lat: 33.9550,
    lng: -83.3590,
    image: require('../assets/building/rivermillapartments.png'), // Add image path
  },
  {
    id: 6,
    name: 'Eclipse on Broad',
    price: 1200,
    bedrooms: 3,
    bathrooms: 2,
    furnished: true,
    location: 'Athens, GA',
    lat: 33.9510,
    lng: -83.3540,
    image: require('../assets/building/eclipseonbroad.png'), // Add image path
  },
];

export default apartmentsData;
