import React from 'react';

const ApartmentList = ({ apartments, onSelectApartment }) => {
  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: '#BA0C2F' }}>Apartments</h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)', // Two apartments per row
          gap: '20px',
        }}
      >
        {apartments.map((apartment) => (
          <div
            key={apartment.id}
            onClick={() => onSelectApartment(apartment)}
            style={{
              border: '1px solid #ddd',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              backgroundColor: '#fff',
            }}
          >
            <img
              src={apartment.image}
              alt={apartment.name}
              style={{ width: '100%', height: '200px', objectFit: 'cover' }}
            />
            <div style={{ padding: '15px' }}>
              <h3 style={{ color: '#BA0C2F' }}>{apartment.name}</h3>
              <p>Price: ${apartment.price} / month</p>
              <p>Bedrooms: {apartment.bedrooms}, Bathrooms: {apartment.bathrooms}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApartmentList;
